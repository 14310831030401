import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockIntroduction from "../components/blockIntroduction"
import TitleTextCol from "../components/titleTextCol"
import Cta from "../components/cta"
import MetaImage from "../images/featured/loyall-featured-image-marketing-light.jpg"
import SendtoDeliverFavicon from "../images/favicon-sendtodeliver.png"
import Accordions from "../components/accordions"
import ThreeImagesGallery from "../components/threeImagesGallery"
import Image from "../components/elements/image"
import { Container, Row, Col } from "react-bootstrap"
import LabelIntroduction from "../components/labelIntroduction"
import HeaderImageArrowDown from "../components/headerImageArrowDown"

const MarketingPage = props => {
  return (
    <Layout>
      <Seo
        title="Loyall – Met marketing bieden we meerwaarde aan je doelgroep"
        image={{ src: MetaImage, height: 628, width: 1200 }}
        description="Het gaat niet alleen om het hebben van een goed verhaal, maar ook hoe je deze overbrengt op je doelgroep. Met marketing zorgen we ervoor dat je verhaal meerwaarde biedt en triggert om over te gaan tot actie."
        pathname={props.location.pathname}
        favicon={SendtoDeliverFavicon}
      />
      <div className="page-wrapper">
        <LabelIntroduction
          label="marketing"
          title="We elevate your brand."
          subtitle="Marketing is meer dan alleen je verhaal delen. Aan wie vertel je het en op welke manier? Maar nog belangrijker: wat wil je met je verhaal bereiken?"
        />
        <Container className="image-header">
          <Image fadeIn={true} imgsrc="loyall-label-marketing-header.jpg" />
        </Container>
        <TitleTextCol
          title="Met marketing zorg je ervoor dat jouw verhaal meerwaarde biedt aan je doelgroep."
          paragh1="We geloven dat het bieden van meerwaarde de sleutel is tot het triggeren van een reactie en het aangaan van relaties. Waar is je doelgroep naar op zoek? Wat willen ze lezen, zien en horen? Waar gaat hun hart sneller van kloppen? Met verschillende vormen van online marketing bouwen we bruggen, smeden we relaties, zorgen we voor vindbaarheid en verhogen we conversies."
        />
        <BlockIntroduction
          title="Hoe het werkt"
          intro={<h3>Wij verbinden de juiste mensen aan jouw merk.</h3>}
          padding={{ top: 80, bottom: 0, left: 15, right: 15 }}
        />
        <HeaderImageArrowDown
          imageBig={"loyall-label-marketing-hoe-het-werk-1.jpg"}
          imageSmall={"loyall-label-marketing-hoe-het-werk-2.jpg"}
          elementId="marketing-two-images"
          delay={200}
          animationDuration={600}
        />
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 4 }}>
              <Accordions
                content={[
                  {
                    title: "Building brands",
                    text: "Merken en organisaties versterken, daar hebben we een grote passie voor. We versterken je website met de juiste content, denken strategisch met je mee en geven inhoudelijke trainingen voor als je zelf wilt gaan bouwen. Met storytelling vertellen we je verhaal op de juiste manier voor jouw doelgroep.",
                    list: [
                      "web strategy",
                      "marketing courses/training",
                      "web content",
                      "marketing strategy",
                      "storytelling",
                      "recruitment marketing",
                    ],
                  },
                  {
                    title: "Motivating people",
                    text: "Wat triggert mensen om te converteren? Op welke manier bied je inhoudelijke meerwaarde? Actie stimuleren doe je met content marketing, denk aan whitepapers, checklists en andere lead magnets. Met social care bouwen we aan relaties met je huidige en nieuwe volgers op social media zodat jouw merk blijft groeien.",
                    list: [
                      "content marketing",
                      "video",
                      "blogging",
                      "social media management",
                      "social care",
                    ],
                  },
                  {
                    title: "Activate people",
                    text: "Hoe activeren we jouw doelgroep? Met onze lifecycle automation brengen we jouw bestaande databestand in beweging. Met campagnes op social media en Google bereiken we de juiste mensen. SEO zorgt ervoor dat jouw website nog beter vindbaar wordt.",
                    list: [
                      "lead generation",
                      "e-mail marketing",
                      "social campaigns",
                      "lifecycle automation",
                      "SEA",
                      "SEO",
                      "conversion optimization",
                    ],
                  },
                  {
                    title: "Stronger relationships",
                    text: "Wij streven naar duurzame relaties en dat willen we ook voor onze partners en hun klanten. HubSpot, één van de beste CRM’s wereldwijd, is bij uitstek de manier om aan sterke relaties te bouwen. Of denk aan retargeting om bekenden opnieuw te motiveren om jouw product of dienst af te nemen.",
                    list: [
                      "nurture campaigns",
                      "retargeting & remarketing",
                      "CRM (HubSpot)",
                      "HubSpot onboarding",
                      "customer journey",
                      "candidate journey",
                      "employer branding",
                    ],
                  },
                ]}
                padding={{ top: 60, bottom: 20, left: 15, right: 15 }}
              />
            </Col>
          </Row>
        </Container>
        <Cta
          verticalStyling={true}
          title=""
          intro="Klinkt goed toch? Check hier wat we al hebben gedaan."
          link="/"
          linkText="Binnenkort meer"
          className="pb-lg-0"
        />
        <ThreeImagesGallery
          imageBig={"loyall-label-marketing-portfolio-1.jpg"}
          imageSmall1={"loyall-label-marketing-portfolio-2.jpg"}
          imageSmall2={"loyall-label-marketing-portfolio-3.jpg"}
          padding={{ top: 0, bottom: 40, left: 15, right: 15 }}
          delay={250}
          animationDuration={600}
        />
        <Cta
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
        />
      </div>
    </Layout>
  )
}
export default MarketingPage
